@import "bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');

@font-face {
    font-family: Manrope-VariableFont;
    src: url("/assets/fonts/Manrope-VariableFont.ttf");
    font-display: swap;
}

@font-face{
    font-family: Helvetica-Neue;
    src: url("/assets/fonts/Helvetica\ Neue\ Medium\ Extended.ttf");
    font-display: swap;
}

@font-face{
    font-family: HUAWEIFont-Bold;
    src: url("/assets/fonts/HuaweiFont-Bold.ttf");
    font-display: swap;
}

@font-face{
    font-family: HUAWEIFont-Regular;
    src: url("/assets/fonts/HuaweiFont-Regular.ttf");
    font-display: swap;
}

/** Colours **/
:root {
    --hc-bg-light: #efefef;
    --hc-dark: #7a7a7a;
    --bg: #f5f5f5;
    --hc-bg-primary: #000;
    --hc-bg-secondary: #F8F9FA;
    --hc-red: red;
    --bg-grey: #d7d7d7;
}

app-root{
    width: 100%;
    overflow-x: hidden;
}

/*Titles & Text*/
h1 {
    font-size: 64px;
}

h1,
h2{
    font-family: 'HuaweiFont-Bold', Open-sans;
    font-weight: normal;
    line-height: 1.2em;
}

h3, h4, h5 {
    font-family: 'HuaweiFont-Regular', Open-sans;
    font-weight: 400;
}

h2 {
    font-size: 1.9em;
}

a {
    color: black !important;
}

a:-webkit-any-link {
    text-decoration: none !important;
}

.bg-grey {
    background: #d7d7d7;
}

.nav-logo {
    max-width: 220px;
    width: 220px;
}

.section-title::after
{
  content: '';
  margin: 0 auto;
  border-bottom: 3px solid var(--hc-red);
  padding: 5px 0 5px 0;
  width: 50px;
  display: block;
}

.left-section-title::after{
  content: '';
  border-bottom: 3px solid var(--hc-red);
  padding: 5px 0 5px 0;
  width: 50px;
  display: block;
}

p {
    font-family: "Helvetica Neue", Sans-serif;
}

/** Text End **/

body {
    color: #000 !important; 
    background: var(--bg);
    margin: 0;
    overflow-x: hidden !important;
    padding: 0 !important;
}

.signup-btn {
    @media screen and (max-width: 768px){
        display: none;
    }
}

.fixed-top-strip {
    height: 1.725rem;
}

.navbar {
    padding-left: 50px;
    padding-right: 50px;
    height: 80px;
    border-bottom: 1px solid #e6e6e6;
    z-index: 2;
}

.navbar-nav .nav-item {
    cursor: pointer;
}

.container-fluid {
    padding: 0 !important;
    overflow-x: hidden !important;
}

/** Btns **/

.btn-primary {
    font-size: 13px;
    font-weight: 800;
    background-color: var(--hc-bg-primary) !important;
    color: #fff !important;
    padding:10px 20px 10px 20px;
    border-radius: 40px;
    border: 2px solid #000 !important;
}

.btn-secondary {
    color: #000;
    font-size: 13px;
    font-weight: 800;
    background-color: white;
    padding:10px 20px 10px 20px;
    border: 2px solid #000;
    border-radius: 40px;
}

.btn-tertiary {
    background: none;
    border: none;
}

.form-check-input:checked {
    background:#c7000b;
}

/** Btns **/

.carousel{
    margin-top:75px;
}

.navbar-nav {
    margin-left: -150px;
    position: fixed;
}

.dropdown-menu {
    margin-top: 20px !important;
    margin-left: -35px;
    border-radius: 0;
    background-color: #F8F9FA;
    border: 0px;
    border-bottom: 2px solid red;
    z-index: 999 !important;
    padding: 25px 15px 25px 15px;
}

.dropdown-item:active {
    background: #d7d7d7;
}


.dropdown-item {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #d7d7d7;
}

.dropdown-item:last-of-type{
    border-bottom: none;
}

.swiper-wrapper {
    height: 80vh;
}

.price-text {
    font-size: 16px;
}

.footer-nav {
    list-style: none;
}

.banner {
    min-height: 500px !important;
}

.product-card {
    display:flex !important;
    flex-direction: row;
    border-radius: 14px;
    margin-top: 3.375rem !important;
}

.card-text {
    padding: 30px;
}

.card-img-right{
    border-radius: 0 14px 14px 0;
}

.card-img-left {
    border-radius: 14px 0 0 14px;
}

.card-descr {
    width: 400px;
}

// #collapseAccLiqTGFwdG9wICYgVGFibGV0 {
//     transform: translate(650px, 100px);
// }
.gaq-form {
    position:relative;
    top:75%;
    -webkit-transform: translateY(25%);
  -ms-transform: translateY(25%);
  transform: translateY(25%);
}

.blog-section {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
}

.blog-wrapper {
    padding: 75px 0 75px 0 !important;
}

.faq-wrapper{
    padding: 150px 0 75px 0 !important;
}

.faq-wrapper-row{
    margin-left: auto;
    margin-right: auto;
}

.accordion-flush .accordion-item .accordion-button {
    background-color: var(--bg);
    border: none;
}

.accordion-button:focus{
    box-shadow: none;
}

.accordion-body {
    background-color: var(--bg);
}

.blog-card {
    border: none;
    cursor:pointer;
    width: 24rem;
    margin-bottom: 20px;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
}

.blog-body {
    background: var(--bg);
}

.carousel .carousel-inner {
    max-height: 450px;
}

.img-wrapper {
    overflow: hidden;
}

.img-wrapper img {
    -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.img-wrapper:hover img {
    -webkit-transform: scale(1.3);
	transform: scale(1.3);
}

.hero-item::before{
    height: 200px;
    width: 100%;
    content: "";
    background: linear-gradient(rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
}

.contact-section {
    margin-top: 50px !important;
    margin-left: 75px;
    margin-right: 75px;
}

.contact-section input {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
    border-radius: 0;
}

.contact-section select {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
    border-radius: 0;
}

.contact-section textarea {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
    border-radius: 0;
}

.contact-details {
    margin-left: -20px;
}

.contact-item {
    margin-left: -40px;
    font-size: 18px;
    line-height: 2.6rem;
}

.categories-nav{
    display: inline-flex;
    list-style: none;
    justify-content: center;
    
}

.product-nav {
    list-style: none;
    padding-top: 3.375rem;
    display: inline-flex;
    justify-content: space-around;
    gap: 100px;
}

.product-item {
    border-bottom: 2px solid transparent;
}

.product-item img {
    margin-bottom: 25px;
    height: 76px !important;
    aspect-ratio: 2/1;
    object-fit: contain;
}

.faq-col{
    margin-left: auto;
    margin-right: auto;
}

.product-item:hover {
    border-bottom: 2px solid var(--hc-bg-primary);
    transition: ease 2s;
}

.product-cards {
    border-radius: 20px;
}

.headline {
    padding-left: 150px;
    padding-right: 150px;
}

.banner-heading {
    font-size: 50px;
    color: white;
}

.banner-text {
    padding-top: 4.375rem;
    padding-left: 4.375rem;
    text-align: left !important;
}

.blog {
    padding: 2rem 0 2rem 0;
}

.hero {
    min-height: 450px;
    margin-top: 75px;
}

.hero-img {
    width: 100% !important;
}

.hero-text {
    padding-top: 150px;
    margin: auto;
    color: white;
}

.product-carousel {
    padding: 0rem 0 2rem 0;
    margin-left: 0px;
    margin-right: 0px;
}

.product-box {
    padding: 10px;
    max-height: 600px;
    text-align: center;
    border-radius: 20px; 
    border: none;
    // padding-bottom: 50px;
}

.box-img-top {
    padding-top: 50px;
    width: 200px !important;
    margin-bottom: 35px;
}

.breadcrumb {
    margin-left: 20px;
}

.search input{
    padding: 10px 15px 10px 15px;
    width: 250px !important;
    border: 1px solid var(--hc-bg-light);
}

.search-btn {
    padding: 10px 15px 10px 15px;
    background: white !important;
    border: 1px solid var(--hc-bg-light);
    cursor: pointer;
}

.search-btn-text {
    background: none;
    border: none;
}

.product-carousel-img {
    height: 150px;
    margin-bottom: 20px;
    width: auto;
}

.footer {
    padding-left: 50px !important;
    padding-right: 50px !important;
}

.icon-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.icon-box {
    max-width: 200px;
}

.icon-box p {
    font-size: 14px;
}

.box-icon {
    width: 64px;
    height: auto;
}

.icon-section {
    padding: 0 0 75px 0;
}

.contact-form input[type="text"] {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
}

.contact-form input[type="number"] {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
}

.contact-form input[type="email"] {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
}

.contact-form select {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
}

.contact-form input[type="checkbox"] {
    padding: 0;
    border: 1px solid var(--hc-bg-light);
}

.category-menu-item {
    border-radius: 0 !important;
    border-right: 1px solid var(--hc-bg-light) !important;
}

.category-menu {
    margin-top: 75px;
    border-bottom: 1px solid var(--hc-bg-light);
}

.category-menu .nav-item:hover {
    color: red;
}

.category-nav {
    padding-top: 50px;
}

.category-nav .nav-item .nav-link {
    background: none;
    border-radius: 0;
}

.category-nav .nav-link.active[aria-selected="true"] {
    border-bottom: 2px solid red;
}

.category-nav .nav-link.active[aria-selected="false"] {
    border-bottom: none;
}

.long-text .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: none;
    color: #000;
    border: 2px dashed var(--hc-bg-primary);
    border-radius: 20px;
}

.long-text .tab-pane {
    transition: opacity .5s ease 0s;
}

.long-text {
    padding-bottom: 0;
    margin-bottom: 0;
}

.carousel-caption {
    top: 25%;
    left: 10%;
    text-align: left;
    z-index: 2 !important;
}

.feature-box {
    //position: absolute;
    //transform: translateY(90px) translateX(20px);
    width: 450px;
    border: none !important;
    z-index: 99;
    text-align: left !important;
    color: #000000;
}

.feature-box .card {
    border: none !important;
}

.promo-card-ul{
    text-align: left !important;
}

#checkRemeber {
    border: 1px solid var(--hc-bg-primary) !important;
}

.social {
    height: 24px;
}

.social-wrapper small {
    padding-top: 5px;
}

.copyright-wrapper {
    display: flex;
    justify-content: start;
}

.social-wrapper {
    display: flex;
    justify-content: end;
    gap: 10px;
}

.cmb-footer {
    margin-left: 1.35em;
}

.insure-notice {
    margin-bottom: 1em;
}

.footer-nav {
    font-size: 14px;
    padding-top: 2em;
}

.footer-nav h6 {
    font-size: 14px;
    font-weight: 600 !important;
}

.footer-nav a {
    color: var(--text-dark);
    text-decoration: none;
}

.modal-header{
    height: 50px;
}

.modal-dialog {
    max-width: 850px;
    margin: 8rem auto;
}

.cmb-form input[type="text"]{
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light) !important;
    border-radius: 0;
}

.cmb-form input[type="number"]{
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light) !important;
    border-radius: 0;
}

.cmb-form select {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
    border-radius: 0;
}

.cmb-form input[type="checkbox"] {
    padding: 0;
    border: 1px solid var(--hc-bg-light);
}

.close {
    background: white;
    border: none;
    padding: 5px 5px 5px 5px;
    border-radius: 100px;
    font-size: 18px;
}

.wrap-img {
    border-radius: 20px;
    float: right;
    margin-left: 10px;
    margin-bottom: 10px;
    margin-right: 70px;
    width: 500px; 
    height: auto
}

//Faq 

.accordion-button:not(.collapsed) {
    color: black !important;
}


// WIZARD CSS

// @font-face{
//     font-family: HUAWEIFont-Bold;
//     src: url(../assets/fonts/HUAWEIFont-Bold.ttf);
// }

// @font-face{
//     font-family: HUAWEIFont-Regular;
//     src: url(../assets/fonts/HUAWEIFont-Regular.ttf);
// }

h4,h5,h6 {
    font-family: 'HUAWEIFont-Regular', Open-sans;
    font-weight: 400;
    color: #000 !important;
}

:root{
    --h-red: #c7000b;
    --bg: #F5F5F5;
    --bg-darker: #c9c9c9;
}

.fixed-top{
    width: 100% !important;
}

.btn-primary {
    font-size: 13px;
    font-weight: 800;
    background-color: #000 !important;
    padding:10px 20px 10px 20px;
    border-radius: 40px;
    border: 2px solid #000 !important;
}

.text-red{
    color: var(--h-red) !important;
    padding: 0px;
    margin: 0px;
}

.text-h-gray{
    color: var(--bg) !important;
    padding: 0px;
    margin: 0px;
}

.bg-darker{
    background-color: var(--bg-darker);
    border: none;
    border-radius: 0px;
}

.fs-small{
    font-size: smaller;
}

.fs-smaller{
    font-size: 14px;
}

.wizard-container{
    background-color: var(--bg);
    /* width: 700px; */
    height: 650px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    padding: 0%;
    border-radius: 5px;
}

.wizard-wrapper{
    padding-left: 50px !important;
    padding-right: 50px !important;
    padding-top: 0px !important;
}

input[type="text"] {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
}

input[type="date"] {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
}

.tab-content{
    padding: 0px !important;
    margin: 0px !important;
}

.tab-content select {
    padding: 10px 15px 10px 15px;
    border: 1px solid var(--hc-bg-light);
}

.wizard-container .nav-link{
    color: gray;
}

.quote-tab {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
}

.nav-btn {
    padding: 0 30px;
    min-width: 140px;
    height: 38px !important;
    line-height: 30px !important;
    text-align: center;
    border: 1px solid #111;
    display: inline-block;
    color: #111;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.875em;
    -webkit-transition: background 500ms; /* For Safari 3.0 to 6.0 */
    transition: background 500ms;
}

.nav-btn:disabled {
    padding: 0 30px;
    min-width: 140px;
    height: 38px !important;
    line-height: 30px !important;
    text-align: center;
    border: 1px solid #111;
    display: inline-block;
    color: #111;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.875em;
    -webkit-transition: background 500ms; /* For Safari 3.0 to 6.0 */
    transition: background 500ms;
    opacity: 0.5;
    cursor: not-allowed;
}

.insur-btn-deal{
    padding: 0 30px;
    min-width: 140px;
    height: 38px !important;
    // line-height: 30px !important;
    text-align: center;
    border: none;
    display: inline-block;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.975em;
    -webkit-transition: background 500ms; /* For Safari 3.0 to 6.0 */
    transition: background 500ms;
    border-color: var(--h-red);
    background-color: var(--h-red);
    font-weight: 600;
}

.insur-btn-deal:disabled {
    padding: 0 30px;
    min-width: 140px;
    height: 38px !important;
    line-height: 30px !important;
    text-align: center;
    border: none;
    display: inline-block;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.975em;
    -webkit-transition: background 500ms; /* For Safari 3.0 to 6.0 */
    transition: background 500ms;
    border-color: var(--h-red);
    background-color: var(--h-red);
    font-weight: 600;
    opacity: 0.6;
}

.insur-btn {
    padding: 0 30px;
    min-width: 140px;
    height: 38px !important;
    // line-height: 30px !important;
    text-align: center;
    border: none;
    display: inline-block;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.975em;
    -webkit-transition: background 500ms; /* For Safari 3.0 to 6.0 */
    transition: background 500ms;
    border-color: var(--h-red);
    background-color: var(--h-red);
    font-weight: 600;
}

.insur-btn:disabled {
    padding: 0 30px;
    min-width: 140px;
    height: 38px !important;
    line-height: 30px !important;
    text-align: center;
    border: none;
    display: inline-block;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.975em;
    -webkit-transition: background 500ms; /* For Safari 3.0 to 6.0 */
    transition: background 500ms;
    border-color: var(--h-red);
    background-color: var(--h-red);
    font-weight: 600;
    opacity: 0.6;
}

.nav-btn:hover{
    border-color: var(--h-red);
    background-color: var(--h-red);
    color: #fff;
}

.edit-btn{
    padding: 0 30px;
    /* min-width: 140px; */
    height: 38px !important;
    line-height: 30px !important;
    text-align: end;
    /* border: none; */
    display: inline-block;
    color: var(--h-red);
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.975em;
    -webkit-transition: background 500ms; /* For Safari 3.0 to 6.0 */
    transition: background 500ms;
    border: 2px solid var(--h-red);
    /* background-color: white; */
    font-weight: 600;
}

.btn-h-red{
    padding: 0 30px;
    /* min-width: 140px; */
    height: 38px !important;
    line-height: 30px !important;
    text-align: end;
    /* border: none; */
    display: inline-block;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.975em;
    -webkit-transition: background 500ms; /* For Safari 3.0 to 6.0 */
    transition: background 500ms;
    border: 2px solid var(--h-red);
    background-color: var(--h-red);
    font-weight: 600;
}

.edit-btn:hover{
    border-color: var(--h-red);
    background-color: var(--h-red);
    color: #fff;
}

.tab-content input{
    font-size: normal;
    border: #111;
}

.tab-content select{
    font-size: normal;
    border: #111;
}

.devices-list{
    background-color: var(--bg-darker);
    padding: 10px;
    border: none;
    border-radius: 20px
}

.devices-list-card{
    background-color: var(--bg);
    padding: 10px;
    border: none;
    border-radius: 20px;
}

.devices-list h3{
    padding-left: 20px !important;
}

.devices-list h4{
    padding-left: 10px !important;
}

.stg0-stp0-row {
    width: fit-content;
}

.insur-card {
    padding: 15px;
    max-height: 850px !important;
    border: none;
    border-radius: 20px;
}

.devices-list-card-add{
    background-color: var(--bg-darker);
    margin-top: 1em;
    padding: 15px;
    height: 60px;
    border: 5px dotted var(--bg);
    cursor: pointer;
    /* border-color: #111; */
}

.wizard-footer-container {
    background: #f5f5f5;
}

.wizard-footer {
    justify-content: center;
    bottom: 0;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    margin-right: 30px;
}

.faqContent{
    width: 100% !important;
}

.wf-item {
    list-style-type: none !important;
    font-size: 12px;
    padding: 20px;
}

.wf-item a {
    text-decoration: none;
    color: #000;
}

.details-card{
    height: 100%;
    border: none;
    border-radius: 20px;
    background-color: var(--bg-darker);
}

.fs-7 {font-size: 0.9rem !important;}
.fs-8 {font-size: 0.8rem !important;}

.blog-sidebar-right{
    padding: 1rem;
}

.cmb-form-wrapper {
    background: #d7d7d7;
    padding: 2rem;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}

.author-img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
}

// .form-check{
//     display: flex !important;
//     flex-direction: row !important;
//     justify-content: space-around !important;
// }

.form-check-input {
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.16);
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.16);
}

.form-check-input:focus{
    box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.16);
    -webkit-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 2px 2px 3px 0px rgba(0,0,0,0.16);
}

.form-control {
    border: 1px solid var(--hc-bg-light) !important;
}

.form-control:focus{
    box-shadow: none;
    border: 1px solid var(--hc-bg-light);
}

/**
* Offcanvas menu
**/

.offcanvas .offcanvas-header {
    border-radius: 0px !important;
}

/***
* Tab styles
Breakpoint: 928px
***/

@media screen and (max-width: 928px){

    body{
        width: 100% !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }

}

/***
* Mobile styles
Breakpoint: 768px
***/

@media screen and (min-width: 340px) and (max-width: 768px){

    body{
        width: 100% !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    } 

    h1 {
        font-size: 32px;
    }

    h2 {
        font-size: 24px;
    }

    .carousel {
        margin-top: 0px;
        z-index: 0 !important;
    }

    .nav{
        width: 100% !important;
    }

    .navbar-nav{
        position: fixed;
        left: 0px !important;
        right: 0px !important;
        margin: 0px !important;
        background: white;
        width:100% !important;
    }

    .navbar {
        padding-left: 0;
        padding-right: 0;
        background: white;
    }

    .carousel-caption {
        top: 20%;
        left: 15%;
        text-align: left;
    }

    .banner-btn {
        z-index: 99 !important;
    }

    .lead{
        white-space: pre-wrap;
    }

    .carousel-caption p {
        width: 77% !important;
    }

    .container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .hero-img {
        position: center-left;
        // width: 860px !important;
        width: 100%;
        height: 373px;
    }

    .author-abt{
        margin-top: 15px;
    }

    .blog-card {
        width: 335px !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        margin-right: auto;
        margin-left: auto;
    }

    .blog-section {
        flex-direction: column !important;
        margin-left: auto;
        margin-right: auto;
    }

    .carousel-item {
        background-position: cover !important;
        min-height: 350px !important;
        margin-top: 75px;
    }

    .banner-text {
        padding-top: 0px !important;
        padding-left: 0 !important;
        text-align: left !important;
    }

    .navbar {
        padding-right: 0px;
        padding-left: 0px;
    }

    .navbar-nav {
        margin-left: 0 !important;
    }

    .hero-img {
        // left: -300px !important;
        position: relative;
        object-fit: cover;
        aspect-ratio: 1/1;
    }

    ul.product-nav {
        height: auto;
        display: flex;
        flex-direction: row !important;
        overflow-x: scroll !important;
        overflow-y: scroll;
        margin-left: 0 !important;
        padding-left: 0 !important;
    }

    .product-nav::-webkit-scrollbar {
        display: none;
    }

    .nav-logo {
        max-width: 250px;
        width: 200px;
    }

    .gaq-headline{
        margin-top: 25px;
    }

    .navbar-toggler {
        border: 0 !important;
        border-radius: 0px;
    }

    .feature-box {
        width: 300px !important;
    }

    .icon-row {
        display: flex;
        flex-direction: column;
        justify-content: space-around !important;
        align-items: center;
    }

    .icon-box {
        max-width: 250px;
    }  

    .cover-content-container{
        display: flex !important;
        flex-direction: column !important;
        margin-left: auto !important;
        margin-right: auto !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .headline {
        padding-left: 50px;
        padding-right: 50px;
    }

    .product-grid-wrapper {
        margin-right: 25px;
    }

    .product-card {
        display: flex;
        padding-bottom: 0 !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        flex-direction: column;
        min-width: 310px !important;
        max-width: 350px;
    }

    // .product-cards {
    //     margin-left: 25px;
    //     margin-right: 25px;
    // }

    .product-cards {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }

    .card-descr {
        width: 295px;
    }

    .long-text {
        text-align: left !important;
        margin: auto;
    }

    .wrap-img {
        max-width: 355px !important;
        margin-bottom: 25px;
        margin-top: 15px;
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .btn-group {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 285px;
    }

    .btn-secondary {
        margin-left: 5px !important;
    }

    .btn-group .btn-primary , .btn-secondary, .btn-tertiary{
        margin-top: 20px;
        //width: 275px !important;
        text-align: center;
        justify-content: center;
    }

    .contact-section {
        margin-left: 5px !important;
        margin-right: 5px !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
    }

    .product-box {
        margin-top: 5px !important;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5px;
        max-height: fit-content;
        width: 335px;
    }

    .footer{
        padding-right: 25px !important;
        padding-left: 25px !important;
    }

    .copyright-wrapper {
        justify-content: center !important;
    }

    .social-wrapper {
        justify-content: center !important;
    }

    ul.footer-nav{
        padding-left: 0 !important;
    }

    .footer-nav ul {
        margin-left: 0 !important;
        padding-left: 0 !important;
    }

    .about-section {
        padding-right: 25px !important;
        padding-left:25px !important;
    }

    .reading-row {
        align-items: center !important;
    }

    .product-carousel {
        justify-items: center;
        padding: 0 !important;
    }

    .product-carousel-img {
        height: 100px;
        margin-bottom: 20px;
        width: auto;
    }

    .cmb-footer {
        justify-content: start;
        margin-left: 0;
        margin-bottom: 30px;
    }
 
    .search {
        display: flex;
        width: 60%;
    }

    .search .input-group {
        width: 80%;
        display: flex;
    }

    .offcanvas-menu {
        margin-left:auto;
        margin-right: auto;
        padding:0;
        list-style-type: none;
    }

    .offcanvas {
        width: 100% !important;
        overflow-x: hidden !important;
    }

    .offcanvas-body {
        width: 100%;
    }

    .offcanvas-menu .nav-item {
        margin-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #d7d7d7;
    }

    .wizard-wrapper {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .promo-wrapper{
        padding-left: 40px !important;
        padding-right: 40px !important;
    }

    .product-nav {
        list-style: none;
        padding-top: 3.375rem;
        display: inline-flex;
        justify-content: space-between;
        gap: 35px;
    }

    .gaq-form {
        position:relative;
        -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }

    //Get a quote mobile
    .devices-list-card{
        justify-content: start !important;
        text-align: center !important;
    }

    #deviceBtn{
        margin-top: 20px !important;
    }

    .devices-list-card input {
        min-width: 100%;
    }
}

.text-start {
    font-size: 13px !important;
}

.selectedDeviceType{
    text-transform: capitalize;
}

.gaq-header{
    padding-bottom: 1.5rem;
    padding-top: 0;
    margin-top: 0;
}

#faqItem a{
    cursor: pointer;
}

#product-ft-link{
    cursor: pointer;
}